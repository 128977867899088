<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
  >
    <b-row>
      <form-input
        label="Email"
        v-model="form.email"
        v-validate="`required|email`"
        data-vv-as="Email"
        :col="4"
      />
      <form-input
        label="Username"
        v-model.trim="form.username"
        v-validate="`required`"
        data-vv-as="Username"
        :col="4"
      />
      <form-input
        label="Password"
        v-model.trim="form.password"
        v-validate="form.id ? '' : `required`"
        data-vv-as="Password"
        :col="4"
      />
      <form-input
        label="Access Token"
        v-model.trim="form.access_token"
        v-validate="`required`"
        data-vv-as="Access Token"
        :col="4"
      />
      <form-input
        label="Url Repository"
        v-model.trim="form.url_repository"
        v-validate="`required`"
        data-vv-as="Url Repository"
        :col="4"
      />
      <form-select
        label="User Type"
        v-model="selected.user_type"
        v-validate="`required`"
        data-vv-as="User Type"
        url="/api/statusData/users"
        :trackLable="`name_th`"
        :col="4"
        findInFill="name_eng"
      />
    </b-row>
  </form-modal>
</template>
<script>
export default {
  data() {
    return {
      selected: {
        user_type: "",
      },
      form: {
        id: "",
        email: "",
        username: "",
        password: "",
        access_token: "",
        url_repository: "",
        user_type: "",
      },
    }
  },
  computed: {
    _title() {
      return !this.form.id ? "Create Users" : "Edit User"
    },
  },
  methods: {
    show(data = "") {
      this.clearForm()
      if (data) {
        this.selected.user_type = data.role
        this.form = this.cp(data)
      } else {
        this.generate()
      }
      this.switch("show")
    },
    async generate() {
      try {
        this.form.access_token = await this.api.get("/api/generate/token")
        this.form.url_repository = await this.api.get(
          "/api/generate/urlRepository"
        )
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    async handleSubmit() {
      try {
        const validatetor = await this.$validator.validateAll()
        if (!validatetor) {
          this.alertValidate()
          return ""
        }

        const form = this.cp(this.form)
        form.user_type = this.selected.user_type.name_eng

        await this.api.createOrUpdate("/api/userData", form, "User Data")
        this.$emit("reload", "")
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
